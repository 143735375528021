(function() {
    var isProd = (document.location.hostname.indexOf('attuneinsurance.com') !== -1) || (document.location.hostname.indexOf('quoting-portal.com') !== -1);
    var env_key;

    // Based on an (admittedly incomplete) list of mobile user-agent strings, here: https://stackoverflow.com/a/11381730
    const mobileUserAgentStrings = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];
    const isMobile = mobileUserAgentStrings.some((userAgentString) => {
      return navigator.userAgent.match(userAgentString);
  });

    // Based on the code excerpt here: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
    var isFirefox = typeof InstallTrigger !== 'undefined';
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    if (isMobile || isSafari || isFirefox) {
      // Currently there is an incompatibility between Firefox, Sprig, and our version of Angular (8.3).
      // Once Angular is updated, we can remove this check.
      return;
    }

    // This list should be kept in sync with the one in app.component.ts
    const disallowedPages = [
      '/settings/commission'
    ];

    if (disallowedPages.includes(document.location.pathname)) {
      return;
    }

    if (isProd) {
      env_key = 'qevF_7z89u';
    } else {
      env_key = '392ssutqH'
    }
    (function(l,e,a,p) {    
      if (window.Sprig) return;
      console.log('now adding Sprig');
      window.Sprig = function(){S._queue.push(arguments)}
      var S = window.Sprig;
      S.appId = a;
      S._queue = [];
      window.UserLeap=S;
      a=l.createElement('script');
      a.async=1;a.src=e+'?id='+S.appId;
      p=l.getElementsByTagName('script')[0];
      p.parentNode.insertBefore(a, p);
    })(document, 'https://cdn.sprig.com/shim.js', env_key);
  })();