(function() {
  var isProd = document.location.hostname.indexOf('attuneinsurance.com') !== -1 || document.location.hostname.indexOf('quoting-portal.com') !== -1 ;
  var isE2E = document.location.hostname === 'e2e';

  if (isProd) {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAJaLfXzmF4hpnzmRofzkuRmKfZJRoDuHg&libraries=places';
    document.body.appendChild(scriptElement);
  } else if (!isE2E) {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBh-PWHWNOsBVVN3H2erSgUIBYlKtFe26s&libraries=places';
    document.body.appendChild(scriptElement);
  }
})();
